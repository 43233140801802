// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-aansluiten-js": () => import("./../src/pages/aansluiten.js" /* webpackChunkName: "component---src-pages-aansluiten-js" */),
  "component---src-pages-bedankt-js": () => import("./../src/pages/bedankt.js" /* webpackChunkName: "component---src-pages-bedankt-js" */),
  "component---src-pages-contact-js": () => import("./../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-documenten-js": () => import("./../src/pages/documenten.js" /* webpackChunkName: "component---src-pages-documenten-js" */),
  "component---src-pages-doelstellingen-js": () => import("./../src/pages/doelstellingen.js" /* webpackChunkName: "component---src-pages-doelstellingen-js" */),
  "component---src-pages-fotos-js": () => import("./../src/pages/fotos.js" /* webpackChunkName: "component---src-pages-fotos-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-vblkalender-js": () => import("./../src/pages/vblkalender.js" /* webpackChunkName: "component---src-pages-vblkalender-js" */),
  "component---src-pages-wedstrijdverslagen-js": () => import("./../src/pages/wedstrijdverslagen.js" /* webpackChunkName: "component---src-pages-wedstrijdverslagen-js" */),
  "component---src-templates-announcement-js": () => import("./../src/templates/announcement.js" /* webpackChunkName: "component---src-templates-announcement-js" */),
  "component---src-templates-fotos-template-js": () => import("./../src/templates/fotosTemplate.js" /* webpackChunkName: "component---src-templates-fotos-template-js" */),
  "component---src-templates-game-recap-js": () => import("./../src/templates/gameRecap.js" /* webpackChunkName: "component---src-templates-game-recap-js" */),
  "component---src-templates-team-js": () => import("./../src/templates/team.js" /* webpackChunkName: "component---src-templates-team-js" */),
  "component---src-templates-teams-js": () => import("./../src/templates/teams.js" /* webpackChunkName: "component---src-templates-teams-js" */)
}

