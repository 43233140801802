module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Oxaco BBC","short_name":"Oxaco BBC","start_url":"/","background_color":"#4b844a","theme_color":"#4b844a","display":"minimal-ui","icon":"src/images/oxaco-logos/oxaco-favicon.png","legacy":true,"cache_busting_mode":"query","include_favicon":true,"theme_color_in_head":true,"cacheDigest":"cbdffd98de6a3130257823c68c136b61"},
    },{
      plugin: require('../node_modules/gatsby-plugin-sentry/gatsby-browser.js'),
      options: {"plugins":[],"dsn":"https://b3edb4f78f8446929059172c65d605f4@sentry.io/1339544","config":{}},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-99105204-4"},
    }]
